<template>
  <v-dialog
    v-model="isShow"
    max-width="800px"
    scrollable
  >
    <v-card>
      <v-card-text>
        <v-img
          v-if="showImg"
          :src="showImg"
          :style="{ transform: `scale(${pdfScale})`, transformOrigin: 'top left' }"
          alt="img"
          aspect-ratio="1.9"
          contain
        />
        <pdf
          v-if="showPdf"
          ref="fileContainer"
          style="border: 1px solid darkgrey; width:100%"
          :style="{ transform: `scale(${pdfScale})`, transformOrigin: 'top left' }"
          :src="showPdf"
          :page="pdfConfig.page"
          @num-pages="pdfConfig.numPages = $event"
          @link-clicked="pdfConfig.page = $event"
        />
      </v-card-text>
      <v-card-actions
        class="blue-grey lighten-5 dx-position-bottom"
      >
        <v-spacer>
          <v-btn
            text
            :disabled="pdfScale <= 1"
            @click="zoomOut"
          >
            <v-icon>mdi-magnify-minus-outline</v-icon>
          </v-btn>
          <span class="pl-1 pr-1">{{ pdfScale }}</span>
          <v-btn
            text
            :disabled="pdfScale >= 5"
            @click="zoomIn"
          >
            <v-icon>mdi-magnify-plus-outline</v-icon>
          </v-btn>
        </v-spacer>
        <v-spacer v-if="showPdf">
          <v-btn
            text
            :disabled="pdfConfig.page === 1"
            @click="pdfConfig.page--"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="pl-1">{{ pdfConfig.page }}</span>
          /
          <span class="pr-1">{{ pdfConfig.numPages }}</span>
          <v-btn
            text
            :disabled="pdfConfig.page === pdfConfig.numPages"
            @click="pdfConfig.page++"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeModal"
        >
          закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import pdf from 'vue-pdf'
  export default {
    name: 'ShowFileContents',
    components: {
      pdf,
    },
    props: {
      isActiveModalImg: {
        type: Boolean,
        default: false,
      },
      openImgInfo: {
        type: String,
        default: '',
      },
      pdf: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        pdfScale: 1,
        pdfConfig: {
          page: 1,
          numPages: 2,
        },
      }
    },
    computed: {
      isShow () {
        return this.isActiveModalImg
      },
      showImg () {
        return this.openImgInfo
      },
      showPdf () {
        return this.pdf
      },
    },
    methods: {
      closeModal () {
        const isClose = false
        this.$emit('isConfirmShowFile', isClose)
        this.pdfScale = 1
        this.pdfConfig.page = 1
      },
      zoomIn () {
        this.pdfScale = (this.pdfScale + 1) || 1
      },
      zoomOut () {
        this.pdfScale = (this.pdfScale - 1) || 2
      },
    },
  }
</script>

<style scoped>
  .myFixed {
    position: fixed;
    bottom: 0;
  }
</style>
