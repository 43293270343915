<template>
  <v-container>
    <v-dialog
      v-model="ifConfirm"
      max-width="600px"
      @click="closeModal"
    >
      <v-card @click.stop>
        <v-card-actions class="blue-grey lighten-5">
          <v-card-title>
            Подтверждение действия
          </v-card-title>
        </v-card-actions>
        <v-progress-linear
          v-if="isModalLoad"
          color="deep-purple lighten-4"
          indeterminate
          rounded
          height="6"
        />
        <v-card-text
          class="d-flex justify-center text-center"
        >
          <div>
            Вы действительно хотите удалить файл
            <span class="font-weight-bold">"{{ localDeleteFileInfo.filename }}.{{ localDeleteFileInfo.fileextension }}"</span>
            ?
          </div>
        </v-card-text>
        <v-card-actions class="blue-grey lighten-5">
          <v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="deleteFile"
            >
              Удалить
            </v-btn>
          </v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeModal"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'ConfirmDeleteFile',
    props: {
      confirmDeleteFile: {
        type: Boolean,
        default: false,
      },
      deleteFileInfo: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        isModalLoad: false,
        localDeleteFileInfo: this.deleteFileInfo,
      }
    },
    computed: {
      ifConfirm () {
        return this.confirmDeleteFile
      },
    },
    watch: {
      deleteFileInfo (value) {
        this.localDeleteFileInfo = value
      },
    },
    methods: {
      deleteFile () {
        this.isModalLoad = true
        const fileName = this.localDeleteFileInfo.filename + '.' + this.localDeleteFileInfo.fileextension
        this.$store.dispatch('deletePostingsFile', this.localDeleteFileInfo.fileid).then((resp) => {
          this.$store.dispatch('setSnackbar', { state: true, text: `Успешно удален файл ${fileName}`, color: 'success', direction: 'top center' })
          this.isModalLoad = false
          this.closeModal()
        }).catch((error) => {
          this.$store.dispatch('setSnackbar', { state: true, text: `У файла ${fileName} - ${error}`, color: 'error', direction: 'top center' })
          this.isModalLoad = false
          this.closeModal()
        })
      },
      closeModal () {
        this.$emit('isConfirmDeleteFile', false)
      },
    },
  }
</script>

<style scoped>

</style>
