<template>
  <v-container
    id="widgets"
    fluid
    tag="section"
    class="pt-0"
  >
    <!-- INFO -->
    <base-material-card
      color="warning"
      inline
      class="col-12"
      filter
    >
      <template #heading>
        <div class="text-h3 font-weight-light">
          <v-icon>mdi-currency-rub</v-icon> Доходы и расходы
        </div>
      </template>
      <template #after-heading>
        <v-switch
          v-model="showFilter"
          color="success"
          label="Фильтр"
          class="mr-3 pt-0"
          dense
        />
      </template>
      <v-card-text>
        <v-row>
          <v-col sm="2">
            <v-select
              v-model="flgviewall"
              :items="appList"
              clearable
              hint="вид заявки"
              persistent-hint
              label="Загружать заявки:"
            />
          </v-col>
          <v-col
            sm="2"
            @click="startDateVisible = !startDateVisible"
          >
            <v-select
              v-model="startDate"
              :items="startDateItems"
              hint="начальная дата"
              persistent-hint
              label="начальная дата:"
            />
            <v-dialog
              v-model="startDateVisible"
              persistent
              max-width="290px"
            >
              <v-date-picker
                v-model="startDate"
                class="my__v-card"
                :locale="'ru'"
                @input="startDateVisible = false"
              />
              <div class="custom_btn">
                <v-btn
                  class="remove_margin orange"
                  @click="resetDateModal('start')"
                >
                  Очистить
                </v-btn>
                <v-btn
                  class="remove_margin"
                  @click="closeDateModal()"
                >
                  Закрыть
                </v-btn>
              </div>
            </v-dialog>
          </v-col>
          <v-col
            sm="2"
            @click="endDateVisible = !endDateVisible"
          >
            <v-select
              v-model="endDate"
              :items="endDateItems"
              hint="конечная дата"
              persistent-hint
              label="конечная дата:"
            />
            <v-dialog
              v-model="endDateVisible"
              persistent
              max-width="290px"
              @click="endDateVisible = false"
            >
              <v-date-picker
                v-model="endDate"
                :locale="'ru'"
                class="my__v-card"
                @input="endDateVisible = false"
              />
              <div class="custom_btn">
                <v-btn
                  class="remove_margin orange"
                  @click="resetDateModal('end')"
                >
                  Очистить
                </v-btn>
                <v-btn
                  class="remove_margin"
                  @click="closeDateModal()"
                >
                  Закрыть
                </v-btn>
              </div>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row
          v-if="showFilter"
          class="d-sm-flex"
        >
          <v-col sm="2">
            <v-autocomplete
              v-model="listFiltersAreaTitle"
              :items="listFilters.areatitle"
              label="Область видимости"
              clearable
            />
          </v-col>
          <v-col sm="2">
            <v-autocomplete
              v-model="listFiltersCustomerTitle"
              :items="listFilters.customertitle"
              label="Организация"
              clearable
            />
          </v-col>
          <v-col sm="2">
            <v-autocomplete
              v-model="listFiltersVendorTitle"
              :items="listFilters.vendortitle"
              label="Получатель"
              clearable
            />
          </v-col>
          <v-col sm="2">
            <v-autocomplete
              v-model="listFiltersStatus"
              :items="listFilters.status"
              label="Статус"
              clearable
            />
          </v-col>
          <v-col sm="2">
            <v-autocomplete
              v-model="listFiltersCreatorName"
              :items="listFilters.creatorname"
              label="Владелец"
              clearable
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="ConHeaders"
          :items="PostingsList"
          class="elevation-3 col-12 pa-2 mt-4"
          :loading="tableLoading"
          no-data-text="нет данных"
          :footer-props="{itemsPerPageText:'Строки на странице:'}"
        >
          <template #item="{ item }">
            <tr
              style="cursor: pointer"
              @click="contractCardShow(item)"
            >
              <td class="w-10">
                {{ item?item.areatitle:'' }}
              </td>
              <!--              <td class="w-5">-->
              <!--                {{ item?item.accounttitle:'' }}-->
              <!--              </td>-->
              <td class="w-10">
                {{ item?item.datedates:'' }}
              </td>
              <td class="w-10">
                {{ item?item.customertitle:'' }}
              </td>
              <td class="w-10">
                {{ item?item.vendortitle:'' }}
              </td>
              <td class="w-10">
                {{ item?getFixed(item.summ):'' }}
              </td>
              <!--              <td class="w-10">-->
              <!--                {{ item?getFixed(item.ndssumm):'' }}-->
              <!--              </td>-->
              <td class="w-15">
                {{ item?item.details:'' }}
              </td>
              <!--              <td class="w-10">-->
              <!--                {{ item?formatElementValue(item.incomingnumber) + '/' + formatElementValue(item.dogovornumber):'' }}-->
              <!--              </td>-->
              <!--              <td class="w-10">-->
              <!--                {{ item?formatElementValue(item.incomingdates) + '/' + formatElementValue(item.dogovordates):'' }}-->
              <!--              </td>-->
              <!--              <td class="w-15">-->
              <!--                {{ item?item.purpose:'' }}-->
              <!--              </td>-->
              <!--              <td class="w-5">-->
              <!--                {{ item?item.factpaymentdates:'' }}-->
              <!--              </td>-->
              <td class="w-10">
                {{ item?getStatusText(item.status):'' }}
              </td>
              <td class="w-10">
                {{ item?item.creatorname:'' }}
              </td>
              <!--              <td class="w-10">-->
              <!--                {{ item?item.paymentdates:'' }}-->
              <!--              </td>-->
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <v-dialog
      v-model="addpopup"
      scrollable
      max-width="1200px"
    >
      <v-card class="contr-card gradient-bg">
        <v-card-title class="blue-grey lighten-5 d-flex justify-center">
          <h3 class="text-h3">
            Заявка № {{ itemSelectfiles ?.postingid }}
          </h3>
          <h4 class="text-h4 leftPosition">
            {{ itemSelectfiles ?.creatorname }}
          </h4>
        </v-card-title>
        <v-card-text
          class="myPadding"
          @drop.prevent="addDropFile"
          @dragover.prevent="dragover"
        >
          <v-container>
            <v-row class="myClass">
              <v-col cols="4">
                <p class="v-label">
                  Область видимости:
                </p>
                <i class="v-value">{{ itemSelect ?.areatitle }}</i>
              </v-col>
              <v-col cols="4">
                <p class="v-label">
                  Дата заявки:
                </p>
                <i class="v-value">{{ itemSelectfiles ?.datedates }}</i>
              </v-col>
              <v-col cols="4">
                <p class="v-label">
                  Категория:
                </p>
                <i class="v-value">{{ itemSelect ?.categorytitle }}</i>
              </v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-row class="myClass">
              <v-col>
                <p class="myClassHeader">Вид операции</p>
              </v-col>
            </v-row>
            <v-row class="myClass">
              <v-col cols="6">
                <input
                  class="input--radio"
                  type="radio"
                  value="optionCheckbox"
                  :checked="isCheckboxChecked(3)"
                  name="checkboxChecked"
                  disabled
                >
                <i class="v-value">Безналичный расчет с контрарентом</i>
              </v-col>
              <v-col cols="6">
                <input
                  class="input--radio"
                  type="radio"
                  value="optionCheckbox2"
                  :checked="isCheckboxChecked(2)"
                  name="checkboxChecked"
                  disabled
                >
                <i class="v-value">Заявка на получение денежных средств</i>
              </v-col>
            </v-row>
            <v-divider class="my-3" />
            <div
              v-if="itemSelectfiles?.flgkind === 3"
            >
              <v-row class="myClass myClass__header-top">
                <v-col cols="6">
                  <p class="v-label">
                    Контрагент-плательщик:
                  </p>
                  <i class="v-value">{{ itemSelectfiles ?.customertitle }}</i>
                </v-col>
                <v-col cols="6">
                  <p class="v-label">
                    Контрагент-получатель:
                  </p>
                  <i class="v-value">{{ itemSelectfiles ?.vendortitle }}</i>
                </v-col>
              </v-row>
              <v-divider class="my-3" />
              <v-row>
                <v-col cols="4">
                  <v-row class="myClass">
                    <v-col>
                      <p>Счет на оплату</p>
                    </v-col>
                  </v-row>
                  <v-row class="myClass">
                    <v-col cols="12">
                      <p class="v-label">
                        Номер:
                      </p>
                      <i class="v-value">{{ itemSelectfiles ?.incomingnumber }}</i>
                    </v-col>
                    <v-col cols="12">
                      <p class="v-label">
                        Дата:
                      </p>
                      <i class="v-value">{{ itemSelectfiles ?.incomingdates }}</i>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row class="myClass">
                    <v-col>
                      <p>Договор на поставку/оказание услуг</p>
                    </v-col>
                  </v-row>
                  <v-row class="myClass">
                    <v-col cols="12">
                      <p class="v-label">
                        Номер:
                      </p>
                      <i class="v-value">{{ itemSelectfiles ?.dogovornumber }}</i>
                    </v-col>
                    <v-col cols="12">
                      <p class="v-label">
                        Дата:
                      </p>
                      <i class="v-value">{{ itemSelectfiles ?.dogovordates }}</i>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row class="myClass">
                    <v-col>
                      <p>Номер лицевого счета</p>
                    </v-col>
                  </v-row>
                  <v-row class="myClass">
                    <v-col>
                      <p class="v-label">
                        Номер:
                      </p>
                      <i class="v-value">{{ itemSelectfiles ?.personalaccount }}</i>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="my-3" />
              <v-row class="myClass">
                <v-col>
                  <p class="v-v-label myClassHeader">
                    Назначение платежа для банка
                  </p>
                  <i class="v-v-value">{{ itemSelectfiles ?.purpose }}</i>
                </v-col>
              </v-row>
              <v-row class="myClass">
                <v-col>
                  <p class="color__red">
                    <i>{{ itemSelectfiles ?.fullpurpose }}</i>
                  </p>
                </v-col>
              </v-row>
              <v-divider class="my-3" />
            </div>
            <div
              v-if="itemSelectfiles?.flgkind === 2"
            >
              <v-row class="myClass">
                <v-col>
                  <p class="v-subtitle myClassHeader">
                    Данные получателя
                  </p>
                </v-col>
              </v-row>
              <v-row class="myClass">
                <v-col cols="4">
                  <p class="v-label">
                    Номер получателя:
                  </p>
                  <i class="v-value">{{ itemSelectfiles ?.recipientempid }}</i>
                </v-col>
                <v-col cols="4">
                  <p class="v-label">
                    ФИО получателя:
                  </p>
                  <i class="v-value">{{ itemSelectfiles ?.empfiofull }}</i>
                </v-col>
              </v-row>
              <v-divider class="my-3" />
            </div>
            <div>
              <v-row class="myClass">
                <v-col>
                  <p class="myClassHeader">
                    Статьи расходов
                  </p>
                </v-col>
              </v-row>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">
                      Статья затрат
                    </th>
                    <th class="text-left">
                      Назначение
                    </th>
                    <th class="text-left">
                      Значение
                    </th>
                    <th class="text-left">
                      Сумма
                    </th>
                    <th class="text-left">
                      Примечание
                    </th>
                    <th class="text-left">
                      Идентификатор
                    </th>
                    <th class="text-left">
                      Описание
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="elems in listPostingsEntriesList"
                    :key="elems.entryid"
                  >
                    <td>{{ elems.costitemname }}</td>
                    <td>{{ elems.costitemtargetname }}</td>
                    <td>{{ elems.costitemtargetexp }}</td>
                    <td>{{ elems ?getFixed(elems.summ) : '' }}</td>
                    <td class="w-25">
                      {{ elems.notes }}
                    </td>
                    <td>{{ elems.entryobjectid }}</td>
                    <td>{{ elems.entryobjecttitle }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
            <v-divider class="my-3" />
            <!--            1-->
            <v-row class="myClass">
              <v-col>
                <p class="v-subtitle myClassHeader">
                  Счет учета (списания)
                </p>
              </v-col>
            </v-row>
            <v-row class="myClass">
              <v-col
                cols="6"
              >
                <p class="v-label">
                  Сумма заявки:
                </p>
                <i class="v-value">{{ itemSelectfiles ? getFixed(itemSelectfiles.summ) : '' }}</i>
              </v-col>
              <v-col
                v-if="itemSelectfiles?.flgkind === 3"
                cols="6"
              >
                <p class="v-label">
                  Сумма НДС:
                </p>
                <i class="v-value">{{ itemSelectfiles ? getFixed(itemSelectfiles.ndssumm) : '' }}</i>
              </v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-row class="myClass">
              <v-col>
                <p class="v-subtitle myClassHeader">Описание</p>
                <i class="v-value">{{ itemSelectfiles ?.details }}</i>
              </v-col>
            </v-row>
            <v-progress-linear
              v-if="!itemSelectfiles && fileLoading"
              color="deep-purple lighten-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-container>
        </v-card-text>
        <v-card-actions class="blue-grey lighten-5">
          <v-btn
            color="blue darken-1"
            text
            @click="openModalFiles"
          >
            Прикрепленные документы ({{itemSelectfiles?.filescount}})
          </v-btn>
        </v-card-actions>
        <v-card-actions class="blue-grey lighten-5">
          <v-spacer>
            <v-btn
              v-if="postingsBtnList.acceptbtncaption"
              color="red darken-1"
              text
              @click="changeStatusCheck(postingsBtnList.acceptstatus)"
            >
              {{ postingsBtnList.acceptbtncaption }}
            </v-btn>
            <v-btn
              v-if="postingsBtnList.rejectbtncaption"
              color="red darken-1"
              text
              @click="changeStatusCheck(postingsBtnList.rejectstatus)"
            >
              {{ postingsBtnList.rejectbtncaption }}
            </v-btn>
          </v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeModal"
          >
            закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalConfirmStatusInfo"
      max-width="500px"
      class="modal__block"
    >
      <v-card>
        <v-container>
          <v-card-title class="align-center title__padding">
            Изменить статус на "{{ statusChangeConfirm.infoText }}"
          </v-card-title>
          <v-card-text
            v-if="isButtonReject"
          >
            <v-text-field
              v-model="infoDetails"
              label="Введите причину"
              hide-details="auto"
              class="input__padding-top"
            />
          </v-card-text>
          <v-card-actions class="blue-grey lighten-5 justify-center">
            <v-btn
              color="green darken-1"
              text
              :disabled="isButtonReject && !infoDetails"
              @click="changeStatus(statusChangeConfirm.idStatus)"
            >
              Подтвердить
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="closeModalCheck()"
            >
              Отмена
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalStatusInfo"
      max-width="500px"
    >
      <v-card-text
        :class="modalTextInfo === 'Статус успешно изменен' ? 'bg-success' : 'bg-error'"
      >
        <v-container>
          <h3 class="text-center">
            {{ modalTextInfo }}
          </h3>
        </v-container>
      </v-card-text>
    </v-dialog>
    <v-dialog
      v-if="isModalFiles"
      v-model="isModalFiles"
      max-width="900px"
      class="modal__block"
      scrollable
    >
      <v-card>
        <v-card-actions class="blue-grey lighten-5">
          <v-card-title>
            Прикрепленные документы
          </v-card-title>
        </v-card-actions>
        <v-progress-linear
          v-if="isLoadUpdateFile"
          color="deep-purple lighten-4"
          indeterminate
          rounded
          height="6"
        />
        <v-card-text
          class="pa-0"
        >
          <v-container>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">
                    Наименование
                  </th>
                  <th class="text-left">
                    Тип документа
                  </th>
                  <th class="text-left">
                    Тип файла
                  </th>
                  <th class="text-left">
                    Размер, байт
                  </th>
<!--                  <th class="text-left">-->
<!--                    Дата загрузки-->
<!--                  </th>-->
                  <th class="text-left">
                  </th>
                  <th class="text-left">
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="elems in itemSelectfiles.fileslist"
                  :key="elems.fileid"
                >
                  <td
                    class="hover-cursor w-200"
                  >
                    {{ elems.filename }}
                  </td>
                  <td>
                    <v-select
                      v-model="elems.filetype"
                      :items="postingsFileTypeList[0]"
                      item-text="description"
                      item-value="id"
                      class="mw-300"
                      persistent-hint
                      label="Выберите тип"
                      @change="updatePostingsInfoFile(elems)"
                    />
                  </td>
                  <td>{{ elems.fileextension }}</td>
                  <td>{{ elems.filesize }}</td>
<!--                  <td>{{ elems.fullDate }}</td>-->
                  <td>
                    <v-icon
                      class="btn__load"
                      @click="downloadFileDocument(elems)"
                    >
                      mdi-download
                    </v-icon>
                    <v-icon
                      v-if="checkFileExtension(elems)"
                      class="btn__view"
                      @click="openFileDocument(elems)"
                    >
                      mdi-eye-outline
                    </v-icon>
                  </td>
                  <td>
                    <v-icon
                      class="btn__delete"
                      @click="deleteFileDocument(elems)"
                    >
                      mdi-delete-forever
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions class="blue-grey lighten-5">
          <v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="openFileExplorer"
            >
              <v-icon>mdi-file-plus</v-icon>
            </v-btn>
          </v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeModalFiles"
          >
            закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-delete-file
      :confirmDeleteFile="confirmDeleteFile"
      :deleteFileInfo="deleteFileInfo"
      @isConfirmDeleteFile="confirmDeleteFileUpdate"
    />
    <show-file-contents
      :isActiveModalImg="isFileModalImg"
      :openImgInfo="openImgInfo"
      :pdf="pdf"
      @isConfirmShowFile="confirmShowFile"
    />
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ConfirmDeleteFile from './modals/ConfirmDeleteFile'
  import showFileContents from './modals/ShowFileContents'
  // import axios from 'axios/index'

  export default {
    name: 'Contracts',
    components: {
      ConfirmDeleteFile,
      showFileContents,
    },
    data () {
      return {
        confirmDeleteFile: false,
        deleteFileInfo: {},
        fileLoading: true,
        showFilter: false,
        itemSelectfiles: [],
        itemSelect: {},
        addpopup: false,
        tableLoading: true,
        Contracts: [],
        PostingsState: [],
        PostingsList: [],
        appList: [],
        startDate: null,
        startDateVisible: false,
        startDateItems: [],
        endDate: null,
        flgviewall: null,
        endDateVisible: false,
        endDateItems: [],
        // categoryInfo: [
        //   { text: 'не указана', value: '01' }, { text: 'Акция "Приведи друга"', value: '02' },
        //   { text: 'Расчет с фрилансером/агенством', value: '03' }, { text: 'Перевод ЗП', value: '04' },
        //   { text: 'Компенсация транспорта/проезда', value: '05' }, { text: 'Компенсация налога самозанятому', value: '06' },
        //   { text: 'Расходы офиса', value: '07' }, { text: 'Общежитие: прочите расходы', value: '08' },
        //   { text: 'Подарки', value: '09' }, { text: 'Общежитие: аренда', value: '10' }, { text: 'Общежитие: питание', value: '11' },
        // ],
        postingsStatusList: [],
        postingsBtnList: {},
        listFilters: {},
        listFiltersAreaTitle: null,
        listFiltersCustomerTitle: null,
        listFiltersVendorTitle: null,
        listFiltersStatus: null,
        listFiltersCreatorName: null,
        listPostingsEntriesList: [],
        modalStatusInfo: false,
        modalTextInfo: null,
        modalConfirmStatusInfo: false,
        statusChangeConfirm: {},
        infoDetails: '',
        isButtonReject: false,
        isConfirmButtonDisabled: false,
        isModalFiles: false,
        modalFileArray: [],
        postingsFileTypeList: [],
        isLoadUpdateFile: false,
        openImgInfo: null,
        isFileModalImg: false,
        pdf: null,
        downloadFile: null,
      }
    },
    computed: {
      ...mapGetters({
      }),
      ConHeaders () {
        return [
          {
            text: 'Область видимости',
            value: 'areatitle',
            sortable: false,
            filter: value => {
              if (this.listFiltersAreaTitle == null) return true
              return value === this.listFiltersAreaTitle
            },
          },
          // {
          //   text: 'Счет учета',
          //   value: 'accounttitle',
          //   sortable: false,
          // },
          {
            text: 'Дата',
            value: 'datedates',
            sortable: false,
          },
          {
            text: 'Организация',
            value: 'customertitle',
            sortable: false,
            filter: value => {
              if (this.listFiltersCustomerTitle == null) return true
              return value === this.listFiltersCustomerTitle
            },
          },
          {
            text: 'Получатель',
            value: 'vendortitle',
            sortable: false,
            filter: value => {
              if (this.listFiltersVendorTitle == null) return true
              return value === this.listFiltersVendorTitle
            },
          },
          {
            text: 'Сумма',
            value: 'summ',
            sortable: false,
          },
          // {
          //   text: 'Сумма НДС',
          //   value: 'ndssumm',
          //   sortable: false,
          // },
          {
            text: 'Описание',
            value: 'details',
            sortable: false,
          },
          // {
          //   text: 'Номер счета/договора',
          //   value: 'incomingnumber' + '/' + 'dogovornumber',
          //   sortable: false,
          // },
          // {
          //   text: 'Дата счета/договора',
          //   value: 'incomingdates' + '/' + 'dogovordates',
          //   sortable: false,
          // },
          // {
          //   text: 'Назначение платежа',
          //   value: 'purpose',
          //   sortable: false,
          // },
          // {
          //   text: 'Оплатить до',
          //   value: 'factpaymentdates',
          //   sortable: false,
          // },
          {
            text: 'Статус',
            value: 'status',
            sortable: false,
            filter: value => {
              if (this.listFiltersStatus == null) return true
              return value === this.getFilterStatus(this.listFiltersStatus)
            },
          },
          {
            text: 'Владелец',
            value: 'creatorname',
            sortable: false,
            filter: value => {
              if (this.listFiltersCreatorName == null) return true
              return value === this.listFiltersCreatorName
            },
          },
          // {
          //   text: 'Дата "оплачен"',
          //   value: 'paymentdates',
          //   sortable: false,
          // },
        ]
      },
      isCheckboxChecked () {
        return (flgkind) => {
          return this.itemSelectfiles && this.itemSelectfiles.flgkind === flgkind
        }
      },
    },
    watch: {
      modalStatusInfo: {
        handler (val) {
          const timeoutDuration = this.modalTextInfo === 'Статус успешно изменен' ? 1000 : 4000
          setTimeout(() => {
            this.modalStatusInfo = false
            this.modalTextInfo = null
          }, timeoutDuration)
        },
        deep: true,
      },
      startDate: {
        handler (val) {
          this.startDateItems = [val]
          this.updateListPostingsInitialFilters()
        },
        deep: true,
      },
      endDate: {
        handler (val) {
          this.endDateItems = [val]
          this.updateListPostingsInitialFilters()
        },
        deep: true,
      },
      flgviewall: {
        handler (val) {
          this.updateListPostingsInitialFilters()
        },
        deep: true,
      },
    },
    created () {
      this.getPostingsStatusList()
      Promise.all(
        [
          this.$store.dispatch('getPostingsState'),
        ]).then((resp) => {
        this.PostingsState = resp[0]
        this.tableLoading = false
        this.parsePostingsState()
        this.getPostingsFileTypeList()
      }).catch((error) => {
        this.tableLoading = false
        console.log('error: ' + error)
      })
    },
    methods: {
      confirmDeleteFileUpdate (updatedValue) {
        this.confirmDeleteFile = updatedValue
        this.contractCardListFiles(this.itemSelect.postingid)
        this.deleteFileInfo = {}
      },
      confirmShowFile (updatedValue) {
        this.isFileModalImg = updatedValue
        this.openImgInfo = null
      },
      updateListPostingsInitialFilters () {
        if (this.startDate !== null && this.endDate !== null && this.flgviewall !== null) {
          this.updateListPostings()
        }
      },
      getFixed (item) {
        return item ? item.toFixed(2) + ' р.' : item
      },
      // getInfoCaterogy (categorytitle) {
      //   if (categorytitle !== null && categorytitle !== undefined) {
      //     return categorytitle === 0 ? 'Не указано' : 'Нужно указать лист категорий'
      //   }
      // },
      // formatElementValue (elem) {
      //   return elem && elem !== undefined && elem !== null && elem !== 'null' && elem.length > 0 ? elem : '-'
      // },
      updateListPostings () {
        this.tableLoading = true
        this.$store.commit('setDateFromListPostings', this.startDate)
        this.$store.commit('setDateTillListPostings', this.endDate)
        this.$store.commit('setFlgviewallListPostings', this.flgviewall)
        this.getListPostings()
      },
      getListPostings () {
        Promise.all(
          [
            this.$store.dispatch('getPostingsList'),
          ]).then((resp) => {
          this.PostingsList = resp[0]
          this.tableLoading = false
          this.getValuesFilters()
        }).catch((error) => {
          this.tableLoading = false
          console.log('error: ' + error)
        })
      },
      getPostingsStatusList () {
        Promise.all(
          [
            this.$store.dispatch('getPostingsStatusList'),
          ]).then((resp) => {
          this.postingsStatusList = resp
        }).catch((error) => {
          console.log('error: ' + error)
        })
      },
      getPostingsFileTypeList () {
        Promise.all(
          [
            this.$store.dispatch('getPostingsFileTypeList'),
          ]).then((resp) => {
          this.postingsFileTypeList = resp
        }).catch((error) => {
          console.log('error: ' + error)
        })
      },
      getStatusText (status) {
        let statusTextInfo = ''
        this.postingsStatusList.forEach(elems => {
          elems.forEach(elem => {
            if (elem.statusid === status) {
              statusTextInfo = elem.title
            }
          })
        })
        return statusTextInfo.length > 1 ? statusTextInfo : 'Пусто'
      },
      getStatusInfo () {
        if (!this.itemSelectfiles) return
        this.postingsStatusList.forEach(elems => {
          elems.forEach(elem => {
            if (elem.statusid === this.itemSelectfiles?.status) {
              this.postingsBtnList = elem
            }
          })
        })
        return this.postingsBtnList
      },
      getValuesFilters () {
        this.listFilters = {}
        const listAreaTitle = []
        const listCreatorName = []
        const listCustomerTitle = []
        const listVendorTitle = []
        const listStatus = []
        if (!this.PostingsList) return
        this.PostingsList.forEach(elems => {
          listAreaTitle.push(elems.areatitle)
          listCreatorName.push(elems.creatorname)
          listCustomerTitle.push(elems.customertitle)
          listVendorTitle.push(elems.vendortitle)
          listStatus.push(elems.status)
        })
        this.listFilters.areatitle = listAreaTitle.filter(function (value, index, self) {
          return self.indexOf(value) === index
        })
        this.listFilters.creatorname = listCreatorName.filter(function (value, index, self) {
          return self.indexOf(value) === index
        })
        this.listFilters.customertitle = listCustomerTitle.filter(function (value, index, self) {
          return self.indexOf(value) === index
        })
        this.listFilters.vendortitle = listVendorTitle.filter(function (value, index, self) {
          return self.indexOf(value) === index
        })
        const statusTextListStatus = []
        listStatus.forEach(elem => {
          statusTextListStatus.push(this.getStatusText(elem))
        })
        this.listFilters.status = statusTextListStatus.filter(function (value, index, self) {
          return self.indexOf(value) === index
        })
      },
      getFilterStatus (status) {
        let statusInfo
        this.postingsStatusList.forEach(elems => {
          elems.forEach(elem => {
            if (elem.title === status) {
              statusInfo = elem.statusid
            }
          })
        })
        return statusInfo
      },
      resetDateModal (elem) {
        if (elem === 'start') {
          this.startDate = null
          this.startDateVisible = false
          this.startDateItems = []
        } else if (elem === 'end') {
          this.endDate = null
          this.endDateVisible = false
          this.endDateItems = []
        }
      },
      closeDateModal () {
        this.endDateVisible = false
        this.startDateVisible = false
      },
      parsePostingsState () {
        const regex = /# (\d+) - (.+?)\n/g
        let match
        while ((match = regex.exec(this.PostingsState))) {
          const value = match[1]
          const text = match[2]
          this.appList.push({ value, text })
        }
        this.flgviewall = this.appList[0]
        this.calculateDates()
      },
      calculateDates () {
        const today = new Date()
        const year = today.getFullYear()
        const month = String(today.getMonth() + 1).padStart(2, '0')
        const day = String(today.getDate()).padStart(2, '0')
        const formattedDate = `${year}-${month}-${day}`

        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        const yearYesterday = yesterday.getFullYear()
        const monthYesterday = String(yesterday.getMonth() + 1).padStart(2, '0')
        const dayYesterday = String(yesterday.getDate()).padStart(2, '0')
        const formattedDateYesterday = `${yearYesterday}-${monthYesterday}-${dayYesterday}`

        this.startDate = formattedDateYesterday
        this.endDate = formattedDate
      },
      updateList (i) {
        this.Contracts = []
        this.tableLoading = true
        const toSend = { activeonly: i }
        this.$store.dispatch('getContractsList', toSend).then((resp) => {
          this.Contracts = resp
          this.tableLoading = false
        }).catch((error) => {
          this.tableLoading = false
          console.log('error: ' + error)
        })
      },
      closeModal () {
        this.addpopup = false
        this.postingsBtnList = {}
        this.listPostingsEntriesList = []
      },
      contractCardShow (item) {
        this.fileLoading = true
        this.itemSelect = item
        this.addpopup = true
        this.itemSelectfiles = null
        this.$store.dispatch('getPostingsEntriesList', item.postingid).then((resp) => {
          this.listPostingsEntriesList = resp
          console.log(this.listPostingsEntriesList)
        }).catch((error) => {
          console.log('error: ' + error)
        })
        this.contractCardListFiles(item.postingid)
      },
      contractCardListFiles (id) {
        this.$store.dispatch('getPostingsCardShow', id).then((resp) => {
          this.itemSelectfiles = resp
          this.fileLoading = false
          this.getStatusInfo()
        }).catch(() => {
          this.itemSelectfiles = null
          this.fileLoading = false
        })
      },
      changeStatusCheck (statusId) {
        this.modalConfirmStatusInfo = true
        if (this.postingsBtnList.acceptstatus === statusId) {
          this.statusChangeConfirm.idStatus = this.postingsBtnList.acceptstatus
          this.statusChangeConfirm.infoText = this.postingsBtnList.acceptbtncaption
        } else if (this.postingsBtnList.rejectstatus === statusId) {
          this.statusChangeConfirm.idStatus = this.postingsBtnList.rejectstatus
          this.statusChangeConfirm.infoText = this.postingsBtnList.rejectbtncaption
          this.isButtonReject = true
        }
      },
      closeModalCheck () {
        this.modalConfirmStatusInfo = false
        this.statusChangeConfirm = {}
        this.infoDetails = ''
        this.isButtonReject = false
        this.isConfirmButtonDisabled = false
      },
      changeStatus (statusId) {
        const itemSelect = this.itemSelect
        const objStatusSet = {
          newstatus: statusId,
          postingid: this.itemSelectfiles.postingid,
          accountid: this.itemSelectfiles.accountid,
          reason: this.infoDetails,
        }
        this.$store.dispatch('getPostingsStatusSet', objStatusSet).then((resp) => {
          this.updateListPostings()
          this.contractCardShow(itemSelect)
          this.modalStatusInfo = true
          this.modalTextInfo = 'Статус успешно изменен'
          this.infoDetails = ''
          this.isButtonReject = false
        }).catch((error) => {
          this.modalStatusInfo = true
          this.modalTextInfo = 'Статус не изменен, ' + error
          this.isConfirmButtonDisabled = false
        })
        this.modalConfirmStatusInfo = false
        this.statusChangeConfirm = {}
      },
      openModalFiles () {
        this.isModalFiles = true
      },
      closeModalFiles () {
        this.isModalFiles = false
        this.modalFileArray = []
      },
      openFileExplorer () {
        const inputAdd = document.createElement('input')
        inputAdd.type = 'file'
        inputAdd.addEventListener('change', async (event) => {
          this.isLoadUpdateFile = true
          const objStatusSet = {
            filedata: event.target.files[0],
            folderid: this.itemSelect.folderid,
          }
          const nameFile = event.target.files[0].name
          this.$store.dispatch('addPostingsFile', objStatusSet).then((resp) => {
            this.isLoadUpdateFile = false
            const infoText = `Файл ${nameFile} успешно добавлен`
            this.$store.dispatch('setSnackbar', { state: true, text: infoText, color: 'success', direction: 'top center' })
            setTimeout(() => {
              this.contractCardListFiles(this.itemSelect.postingid)
            }, 200)
          }).catch((error) => {
            this.$store.dispatch('setSnackbar', { state: true, text: error, color: 'error', direction: 'top center' })
            this.isLoadUpdateFile = false
            setTimeout(() => {
              this.contractCardListFiles(this.itemSelect.postingid)
            }, 200)
          })
        })
        inputAdd.click()
      },
      deleteFileDocument (items) {
        this.confirmDeleteFileUpdate(false)
        setTimeout(() => {
          this.deleteFileInfo = items
          this.confirmDeleteFile = true
        }, 50)
      },
      updatePostingsInfoFile (elems) {
        this.isLoadUpdateFile = true
        const fileName = elems.filename + '.' + elems.fileextension
        this.$store.dispatch('updatePostingsFile', elems).then((resp) => {
          const infoText = `Документ ${fileName} успешно изменил тип`
          this.$store.dispatch('setSnackbar', { state: true, text: infoText, color: 'success', direction: 'top center' })
          this.isLoadUpdateFile = false
        }).catch((error) => {
          this.$store.dispatch('setSnackbar', { state: true, text: error, color: 'error', direction: 'top center' })
          this.isLoadUpdateFile = false
        })
      },
      openFileDocument (elems) {
        this.isLoadUpdateFile = true
        this.isFileModalImg = false
        this.openImgInfo = null
        this.pdf = null
        this.$store.dispatch('getOpenFilesPostings', elems.fileid).then((resp) => {
          const fileextensionLower = elems.fileextension.toLowerCase()
          if (fileextensionLower === 'png' || fileextensionLower === 'jpg' || fileextensionLower === 'jpeg' || fileextensionLower === 'jpg' || fileextensionLower === 'bmp') {
            this.openImgInfo = URL.createObjectURL(new File([resp], 'img', { type: `image/${elems.fileextension}` }))
          } else if (fileextensionLower === 'pdf') {
            this.pdf = URL.createObjectURL(new File([resp], 'pdf', { type: `application/${elems.fileextension}` }))
          }
          this.isFileModalImg = true
          this.isLoadUpdateFile = false
        }).catch((error) => {
          console.log('error - ' + error)
          this.isLoadUpdateFile = false
        })
      },
      downloadFileDocument (elems) {
        console.log(elems)
        const link = document.createElement('a')
        this.isLoadUpdateFile = true
        this.downloadFile = null
        this.$store.dispatch('getOpenFilesPostings', elems.fileid).then((resp) => {
          this.downloadFile = URL.createObjectURL(new File([resp], 'file', { type: `image/${elems.fileextension}` }))
          link.href = this.downloadFile
          console.log(this.downloadFile)
          link.setAttribute('download', elems.filename + '.' + elems.fileextension)
          document.body.appendChild(link)
          link.click()
          this.isLoadUpdateFile = false
        }).catch((error) => {
          console.log('error - ' + error)
          this.isLoadUpdateFile = false
        })
      },
      checkFileExtension (elems) {
        const fileextensionLower = elems.fileextension.toLowerCase()
        return fileextensionLower === 'png' || fileextensionLower === 'jpg' || fileextensionLower === 'gif' || fileextensionLower === 'jpeg' || fileextensionLower === 'jpg' || fileextensionLower === 'bmp' || fileextensionLower === 'pdf'
      },
    },
  }
</script>

<style>
  .remove_margin {
    margin: 0 !important;
  }
  .btn__delete, .btn__view, .btn__load {
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
  }
  .btn__delete:hover {
    color: #E53935;
    background: rgba(229, 57, 53, 0.1);
  }
  .btn__view:hover {
    color: #3558e5;
    background: rgba(53, 141, 229, 0.1);
  }
  .btn__load:hover {
    color: #33a927;
    background: rgba(59, 229, 53, 0.1);
  }
  .v-icon.v-icon::after {
    background: none
  }
  .custom_btn {
    background: #48c666 ;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  .my__v-card {
    border-radius: 5px 5px 0 0;
  }
  .my__v-card .v-date-picker-header .v-btn {
    margin-right: 0 !important;
  }
  .w-10 {
    width: 10%;
  }
  .w-15 {
    width: 15%;
  }
  .w-25 {
    width: 25%;
  }
  .w-200 {
    max-width: 200px;
  }
  #drop_area {
    width: 100%;
    height: 250px;
    background-color: red;
    text-align: center;
    padding-top: 250px;
  }
  .contr-card .v-card__text{
    font-size: 1.2rem;
  }
  .myClassHeader {
    background: #eceff1;
  }
  .bg-success {
    background: #3ff56b;
  }
  .bg-error {
    background: #ff6161;
  }
  .myClass p {
    margin-bottom: 0;
    padding: 0 5px;
  }
  .myClass i {
    padding: 0 5px;
  }
  .myClass__header-top {
    padding-top: 10px;
  }
  .myClass .col {
    padding-top: 0;
    padding-bottom: 6px;
  }
  .myClass .input--radio {
    margin-right: 5px;
  }
  .color__red {
    color: #a70b0b;
  }
  .myPadding {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .modal__block .v-card, .v-card__actions  {
    padding-top: 8px !important;
  }
  .title__padding .v-dialog, .v-card, .v-card__title {
    /*padding: 24px !important;*/
  }
  .input__padding-top {
    padding-top: 0;
    margin-top: 10px;
  }
  .hover-cursor {
    cursor: pointer;
  }
  .mw-300 {
    max-width: 250px;
  }
  .leftPosition {
    position: absolute;
    right: 15px;
  }
</style>
